<template>
  <b-row class="preview-center" no-gutters>
    <b-col>
      <b-form-checkbox
          size="lg"
      ></b-form-checkbox>
    </b-col>
  </b-row>
</template>

<script>
export default {}
</script>
<style scoped>
.preview-center {
  text-align: center;
}
@media only screen and (max-width: 991px) {
  .preview-center {
    text-align: left;
  }
}
</style>